import React from 'react'
import brigitteJpg from '../images/brigitte.jpg'
import brigitteWebp from '../images/brigitte.webp'
import sabrinaJpg from '../images/sabrina.jpg'
import sabrinaWebp from '../images/sabrina.webp'
import IndexLayout from '../layouts'

export default function Kontakt() {
  return (
    <IndexLayout>
      <h1 className="sectionTitle">Kontakt</h1>
      <p style={{ textAlign: 'center' }}>Starte durch und nimm mit mir Kontakt auf:</p>
      <br />
      <div className="kontakt__grid">
        <div className="kontakt__gridItem1">
          <picture>
            <source srcSet={brigitteWebp} type="image/webp" />
            <source srcSet={brigitteJpg} type="image/jpeg" />
            <img
              src={brigitteJpg}
              style={{
                borderRadius: '100%',
                tranform: 'translateX(50)'
              }}
              alt="Brigitte"
            />
          </picture>
        </div>
        <div className="kontakt__gridItem3">
          <h2 className="kontakt__h3">DI Brigitte Fink</h2>
          Lebens- und Sozialberaterin,
          <br />
          Yogalehrerin
          <br />
          Architektin
          <br />
          6323 Bad Häring/Tirol
          <br />
          Tel.: 0660-168 4210
          <br />
          Mail:{' '}
          <a href="mailto:fink.bmf@gmail.com" className="kontact_briggitteEmailLink">
            fink.bmf@gmail.com
          </a>
        </div>
      </div>
    </IndexLayout>
  )
}
